import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { between } from 'polished';

const StyledWidth = styled.div`
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: ${between('24px', '90px')};
	padding-right: ${between('24px', '90px')};

	&.center {
		text-align: center;
	}
`;

const Width = ({ className, children }) => <StyledWidth className={className}>{children}</StyledWidth>;

Width.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Width;
