import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { between } from 'polished';

const StyledSection = styled.section`
	margin-top: ${between('72px', '140px')};

	h2 {
		font-size: ${between('24px', '42px')};
	}
	
	p {
		color: #637178;
		font-size: ${between('18px', '20px')};
	}
`;

const Section = ({ className, children }) => <StyledSection className={className}>{children}</StyledSection>;

Section.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Section;
