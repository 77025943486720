import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { between } from 'polished';

const Wrapper = styled.span`
	position: relative;
	background: linear-gradient(0deg, rgba(0, 132, 255, 1) 0%, rgba(0, 158, 255, 1) 100%);
	color: #fff;
	border-radius: 4px;
	text-decoration: none;
	font-weight: 500;
	display: inline-block;
	user-select: none;
	transition: all 0.3s;

	&:before {
		content: '';
		background: #fff;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none !important;
		transition: all 0.3s;
	}

	&:hover,
	&:focus {
		text-decoration: none;
		box-shadow: 0 2px 20px rgba(0, 132, 255, 0.5);
		outline: none;

		&:before {
			opacity: 0.1;
		}
	}

	&.big {
		font-size: ${between('18px', '20px')};
		margin-top: ${between('24px', '36px')};

		& > * {
			padding: 24px 36px;
		}

		&:hover,
		&:focus {
			transform: translateY(-3px);
		}
	}

	&.small {
		&:hover,
		&:focus {
			box-shadow: 0 1px 3px rgba(0, 132, 255, 0.5);
		}
	}

	a {
		color: #fff !important;
		display: block;
		text-decoration: none !important;
	}
`;

const Button = ({ className, children, to }) => (
	<Wrapper className={className}>
		{to !== undefined && <Link to={to}>{children}</Link>}
		{to === undefined && <span>{children}</span>}
	</Wrapper>
);

Button.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Button;
