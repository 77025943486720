import { Link } from 'gatsby';
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { between } from 'polished';

import Width from './width';
import Button from './button';
import LogoImg from '../images/logo.svg';

const StyledNavigation = styled.div`
	background: #fff;
	height: 70px;
	top: -1px;
	padding-top: 10px;
	z-index: 100;
	transition: all 0.3s;

	&.sticky {
		position: sticky;
	}

	&.stuck {
		background: #fff !important;
	}

	@media (min-height: 700px) {
		height: 100px;
		padding-top: 20px;
	}

	.flex {
		display: flex;
		align-items: center;
	}

	.logo {
		width: ${between('40px', '50px')};
		fill: #637178;
		transition: fill 0.3s;

		&:hover {
			fill: #303030;
		}
	}

	ul {
		margin-left: auto;
		list-style-type: none;

		li {
			display: none;
			margin-left: ${between('12px', '14px')};
			border-radius: 4px;

			&:not(.not-on-mobile) {
				display: inline-block;
			}

			a {
				text-decoration: none;
				display: block;
				padding-top: 6px;
				padding-bottom: 6px;
				padding-left: ${between('12px', '18px')};
				padding-right: ${between('12px', '18px')};
				border-radius: 4px;
				color: #637178;
				font-weight: 500;
				transition: all 0.3s;

				&:hover {
					color: #303030;
				}
			}

			@media (min-width: 600px) {
				&.not-on-mobile {
					display: inline-block;
				}
			}
		}
	}

	&.blue:not(.stuck) {
		background: rgb(0, 158, 255);

		.logo {
			fill: #d4ecff;

			&:hover {
				fill: #fff;
			}
		}

		ul li {
			a {
				color: #d4ecff;

				&:hover {
					color: #fff;
				}
			}

			&.sign-up > span {
				background: rgba(255, 255, 255, 0.15);

				&:hover,
				&:focus {
					box-shadow: none;
				}
			}
		}
	}
`;

const Navigation = ({ blue, sticky }) => {
	const ref = useRef(null);
	useEffect(() => {
		const observer = new IntersectionObserver(
			([e]) => {
				if (sticky && blue && ref.current) ref.current.classList.toggle('stuck', e.intersectionRatio < 1);
			},
			{
				threshold: 1,
			},
		);
		observer.observe(ref.current);
	}, [ref]); // eslint-disable-line react-hooks/exhaustive-deps

	const classBlue = blue ? 'blue' : '';
	const classSticky = sticky ? 'sticky' : '';

	return (
		<StyledNavigation ref={ref} className={`wrapper ${classBlue} ${classSticky}`}>
			<Width>
				<div className="flex">
					<Link to="/" aria-label="Homepage">
						<LogoImg className="logo" />
					</Link>

					<ul>
						<li className="not-on-mobile">
							<Link to="/">Features</Link>
						</li>

						<li className="not-on-mobile">
							<Link to="/">Pricing</Link>
						</li>

						<li>
							<Link to="/login/">Log in</Link>
						</li>

						<li className="sign-up">
							<Button to="/signup/?ref=nav">Sign up</Button>
						</li>
					</ul>
				</div>
			</Width>
		</StyledNavigation>
	);
};

export default Navigation;
