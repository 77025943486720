import { Link, useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { between } from 'polished';

import LogoImg from '../images/logo.svg';

import Width from './width';

const StyledFooter = styled.footer`
	background: #303030;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.8) 100%);
	padding-top: ${between('86px', '120px')};
	color: #fff;
	clip-path: polygon(0 50px, 100% 0, 100% 100%, 0 100%);

	@media (min-width: 768px) {
		clip-path: polygon(0 80px, 100% 0, 100% 100%, 0 100%);

		@media (min-width: 960px) {
			clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 100%);

			@media (min-width: 1200px) {
				clip-path: polygon(0 150px, 100% 0, 100% 100%, 0 100%);
			}
		}
	}
`;

const FlexWidth = styled(Width)`
	display: flex;

	.main {
		display: none;
		margin-right: auto;
		align-self: center;

		@media (min-width: 768px) {
			display: block;
		}
	}

	.column {
		width: 200px;

		& + .column {
			margin-left: 100px;
		}
	}

	p {
		font-weight: 700;
		margin: 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		margin-top: 10px;

		li {
			font-size: 16px;
			line-height: 36px;

			strong {
				font-weight: 700;
			}
		}
	}

	a {
		color: #fff;
	}
`;

const Logo = styled(Link)`
	display: inline-block;

	.logo {
		width: ${between('120px', '180px')};
		fill: rgba(0, 0, 0, 0.3);
		padding: ${between('10px', '20px')};
	}
`;

const Bottom = styled(Width)`
	background: rgba(0, 0, 0, 0.3);
	color: rgba(255, 255, 255, 0.5);
	font-size: 14px;
	text-align: center;
	margin-top: 36px;
	padding-top: 24px;
	padding-bottom: 24px;

	a {
		color: rgba(255, 255, 255, 0.5);
	}
`;

const Footer = () => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<StyledFooter>
			<FlexWidth>
				<div className="main">
					<Logo to="/">
						<LogoImg className="logo" />
					</Logo>
				</div>

				<div className="column">
					<p>Product</p>
					<ul>
						<li>
							<Link to="/features/">Features</Link>
						</li>
						<li>
							<Link to="/pricing/">Pricing</Link>
						</li>
						<li>
							<Link to="/login/">Log in</Link>
						</li>
						<li>
							<Link to="/signup/?ref=footer">
								<strong>Sign up</strong>
							</Link>
						</li>
					</ul>
				</div>

				<div className="column">
					<p>Legal</p>
					<ul>
						<li>
							<Link to="/terms-and-conditions/">Terms & Conditions</Link>
						</li>
						<li>
							<Link to="/privacy-policy/">Privacy Policy</Link>
						</li>
						<li>
							<Link to="/security/">Security</Link>
						</li>
					</ul>
				</div>
			</FlexWidth>

			<Bottom>
				© <Link to="/">{data.site.siteMetadata.title}</Link> &bull; {new Date().getFullYear()}
			</Bottom>
		</StyledFooter>
	);
};

export default Footer;
